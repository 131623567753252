
html, body{
    	height: 100%;
    	margin: 0;
      background-color: #FFFFCC;
    }
.wrapper{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template:
    'header  header header header '
    'menu  main main  right'
    'menu footer footer footer ';
  grid-gap: 10px;
  background-color: #FFFFCC;
  padding: 10px;

}
.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.header {
  grid-area: header;
  font-size: 1em;
  font-weight: bolder;
  text-align: center;
  }
.menu {grid-area: menu;
    max-width: 200px;
  }
.main {grid-area: main}
.right {grid-area: right}
.footer{
  grid-area: footer;
  color:#CC3300;
  font-family:Verdana, Arial, Helvetica, sans-serif;
  text-align: right;
  padding-right: 10%
}

.textright{
    	text-align: right;
    	padding-right: 50px;
    }

.textcenter{
    	text-align: center;
    	padding-right: 50px;
    }
  
.largefont{
  color: #cc3300;
  font-size: 1.8em;
  font-weight:  bold;
}

h1{

  color: #cc3300;
}

p {font-family:Verdana, Arial, Helvetica, sans-serif; font-size: 16px; line-height: 20px;}

p2 {font-family:Verdana, Arial, Helvetica, sans-serif; font-size: 14px; line-height: 200%;}

p3 {font-family:Verdana, Arial, Helvetica, sans-serif; font-size: 20px; line-height: 200%;}



.footer {color: #c30;
  font-family: Verdana, Arial,Arial, Helvetica, sans-serif; 
  text-align: center;
}


.style1 {color: #CC3300;}

.style2 {font-size: large; font-weight: bold;}

.style3 {font-size: 18px; font-weight: bold; color: #CC3300;}

.style4 {color: #000000; font-size: 20px;}

.style5 {font-size: large; font-weight: bold;color: #CC3300;}

.stress {background-color:#FFFF00}

#navbar, #navbar ul {display:block; margin:0px; padding:0; list-style:none; background:#FFFFCC; font-family: Verdana, Arial, Helvetica, sans-serif; ; font-size:16px; text-align:center; font-weight:bold}


#navbar a, Link			{display:block; padding:6px; width:10em; text-decoration: none; color: #CC3300;}



#navbar a:hover, Link:hover	{color:#FFC;  background:#c30;}

#navbar li 			{background:#FFFFCC; float:left; width:11em;text-align:centre; padding-left:4px}

#navbar li ul 		{display:none}

#navbar li:hover ul	{position:absolute; display:block; width:20em; background:#FFFFCC; border:.15em solid #CC3300; margin-left:9em; margin-top:-4em;}

#navbar li ul li 	{font-size:85%}

#navbar li:hover ul li {border:none; padding:4px}

/*#navbar li ul li 	{float:none; width: auto;} - disable this to let list items form two columns*/

div.content			{clear:left; padding-top: 5%;}
